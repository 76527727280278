import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/user-service/auth-service";
import '../App.css'

const OTPRecovery = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const handleOTPRecovery = (e) => {
    e.preventDefault();

    setMessage("");

      AuthService.getOTP(email).then(
        () => {
          alert("OTP sent to email!")
          navigate("/first-time-login");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
  };

  return (
    <div className="page-container-static-auth">
      <div className="config-list-group-auth">
    <div className="login-container">
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p>Fill in Email to get OTP</p>
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={handleOTPRecovery}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        Get OTP Code
                    </button>
                  </div>
                  {message && (<div /* className="error" */>{message}</div>)}
              </form>
          </div>
        </div>
        </div>
        </div>
    </div>
  );
}

export default OTPRecovery;