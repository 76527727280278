import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/user-service/auth-service";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
import '../App.css'

const RecoverPassword = (loggedInUser) => {

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const isUser = useState({ _id: "", firstName: "", lastName: "", username: "", phoneNumber: "",
  email: "", role: "", profilePicture: "", accessTokens: "" })
  const [currentUser, setCurrentUser] = useState(isUser);

  const [email, setEmail] = useState("");
  const [OTP_Code, setOTPCode] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    setCurrentUser(loggedInUser.loggedInUser);

    
  }, [setCurrentUser, setLoading, setNotification, loggedInUser]);

  const handleRecoverPassword = (e) => {
    e.preventDefault();

    setMessage("");

      AuthService.recoverPasswordOTP(email, password, OTP_Code, ).then(
        (response) => {
          setLoading(true);
          setNotification("Password Updated Successfully!");
          setTimeout(() => {
            setLoading(false);
            setNotification("");
            setCurrentUser(response.data);
            navigate("/home");
            window.location.reload();}, 2300);
          return;
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
  };

  return (
    <div className="App">
    <div className="page-container-static-auth">
      <div className="config-list-group-auth">
    <div className="login-container">
    {isLoading && notification && (<div className="info">{notification}</div>)}
          {message && (<div className="error">{message}</div>)}
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p style={{ fontSize: "13px" }}>Fill in Email and OTP to Update Password</p>
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={handleRecoverPassword}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="OTP_Code">OTP</label>
                    <input
                      type="text"
                      name="OTP_Code"
                      className="form-control"
                      value={OTP_Code}
                      onChange={(e)=>setOTPCode(e.target.value)}
                      placeholder="Enter OTP"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="pass-wrapper">
                    <label htmlFor="password">New Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                      placeholder="Enter New Password"
                      autoComplete="off"
                      required
                    />
                    <i className="pass-icon" 
                        onClick={togglePasswordVisiblity}
                    >
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiFillEye />}
                    </i>
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        Reset Password
                    </button>
                  </div>
              </form>
          </div>
        </div>
        </div>
        </div>
    </div>
    </div>
  );
}

export default RecoverPassword;