import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import ApplicationService from "../services/application-service/application-data-service";
import ViewApplication from "./View-Application";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import '../App.css'
import "bootstrap/dist/js/bootstrap.bundle.js"


const Register = (loggedInUser) => {

    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState();
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        appName: "", version: "", endpoints: [{ env: "", endpoint: "" }], description: ""
    });
    const [formDataArray, setFormDataArray] = useState([{
      env: "", endpoint: "",
    }]);
    const isApplication = useState({ id: "", appName: "", envName: "", description: "", endpoint: "",
        storageStatus: "", upTimeStatus: "",  memoryStatus: "", queryDate: "", notes: "" });
    const [data, setData] = useState([]);
    const [activeApplication, setActiveApplication] = useState(isApplication);
    const [isLoadApplication, setLoadApplication] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const handleCloseModal = () => { setShowModal(false); navigate("/home"); };
    const handleShowModal = () => setShowModal(true);
    
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const handleAddInput = () => {
      setFormDataArray([...formDataArray, { env: "", endpoint: "" }]);
      //setFormData([/* formData,   */...formData.endpoints, { env: "", endpoint: "" }]);
    };

    const handleChangeArray = (e, index) => {
      let { name, value } = e.target;
      let onChangeValue = [...formDataArray];
      onChangeValue[index][name] = value;
      //console.log(formData)
      //let appendedForm = formData.endpoints;
      //appendedForm.push(formDataArray);
      setFormDataArray(onChangeValue);
      //return appendedForm;
    };
  
    const handleDeleteInput = (index) => {
      const newArray = [...formDataArray];
      newArray.splice(index, 1);
      setFormDataArray(newArray);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        //let onChangeValue = [...formData[0].endpoints];
        //onChangeValue[index][name] = value;
        //setFormDataArray(onChangeValue); 
        //console.log(formDataArray)

        //let appendedForm = formData.endpoints;
        //appendedForm.push(formDataArray);
    
        setFormData({
          ...formData,
          //appendedForm,
          //onChangeValue,
          //...formDataArray,
          [name]: value
        });
        //console.log(formData)
       //return appendedForm;
      };
    
        //let i = 0; i < formData.endpoints.length; i++;
        //let n = 0; n < formDataArray.length; n++;
        const validateForm = () => {
        let isValid = true;
        /* let i = 0; i < formData.endpoints.length; i++;
        let n = 0; n < formDataArray.length; n++; */
        const newErrors = {};
    
        if (!formData.appName) {
            newErrors.appName = "App Name is required";
            isValid = false;
        }

        if (!formData.version) {
          newErrors.version = "App Version is required";
          isValid = false;
        }

       /*  if (!formData.endpoints[0].env) {
          newErrors.env = "Env is required";
          isValid = false;
        }

        if (!formData.endpoints[0].endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        } */

        if (!formDataArray[0].env) {
          newErrors.env = "Env is required";
          isValid = false;
        }

        if (!formDataArray[0].endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        } 
  
        if (!formData.description || formData.description) {
            isValid = true;
        }
    
        setErrors(newErrors);
        return isValid;
      }; 

      

    useEffect(() => {
        /* window.scrollTo(0, 0); */

        const appData = loggedInUser.data;
        const user = loggedInUser.loggedInUser
        if (user && appData) {
            setCurrentUser(user);
            setData(appData);
            if (!(user.role === "Admin" || user.role ==="Super Admin")) {
                setMessage("Only Admins can add new Applications!");
                setTimeout(() => {
                    setMessage("");
                    navigate("/home");
                    window.location.reload();}, 3300)
                    return;
            };
        } else {
          return null;
        };



      
      
          setActiveApplication(activeApplication);

        
    }, [setCurrentUser, activeApplication, navigate, loggedInUser]);


    const setViewActiveApplication = (application) => {
        setActiveApplication(application);
        setLoadApplication(true);
      };

    const handleRegisterApp = async (e) => {
        e.preventDefault();

        let appendedForm = formData.endpoints;
        let length = formDataArray.length;
        let lastArray = formDataArray[length - 1];
        //appendedForm.shift();
        //let mapEndpoints = appendedForm.map(k => formDataArray.map(i => ({ env: i[k].env, endpoint: i[k].endpoint })));
        appendedForm.push(...formDataArray /* { env: "", endpoint: "" } */ );
        appendedForm.shift();
        //console.log(mapEndpoints)
        //console.log(formData)
        //console.log(formDataArray)

        if (validateForm()) {
            ApplicationService.createApplication(formData.appName, formData.version,  formData.description,/* formData.env, */
                formData.endpoints).then(
                () => {
                    setSuccess("Application Created Successfully!");
                    setLoading(true);
                    console.log("Form data:", formData);
                    setSubmitted(true); 
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess("");
                        handleCloseModal();
                        window.location.reload();
                      }, 2300)
                },
                (error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setMessage(message);
                }
            );
        
          } else {
            // Form is not valid, display error messages
          }}

          

    return (
<div className="App">
    {currentUser && (currentUser.role === "Admin" || currentUser.role === "Super Admin") && (<div>

        <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, index) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav" key={index}>
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={index} onClick={ () => setViewActiveApplication(item, item.id) }>
                        {/* <i className="bi bi-circle"></i> */}
                        {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                            item.appName === "Nexus" || item.appName === "Unitrust") ? 
                            
                          <img src={require(`../images/${item.appName}-logo.png`)} 
                          alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                          
                          : 
                          <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                        }
                        <span> {item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>

          <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="pagetitle">
                {/* <h1>Dashboard</h1> */}
                <nav className="breadcrumbs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/home">Home</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/users">Users</a></li>
                    <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
                    <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>Create New App</span></li>
                  </ol>
                </nav>
              </div>

          {/* </section>
          </section> */}

<section className="section dashboard">
          <div className="row">
        <div className="col-lg">
      <div className="row">
      <Modal
        show={showModal}
        onHide={() => { handleCloseModal() }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up" style={{ textAlign: "center" }}>
            <h5 style={{ marginLeft: "10px", textAlign: "center", color: "#555" }}>Create Application </h5>
            <p style={{ marginLeft: "10px", textAlign: "center", color: "#555" }}>Fill in the details below</p>
            {/* {message && (<div className="error">{message}</div>)} */}
          </div>

        </Modal.Header>

        <Modal.Body>
        <div /* className="register-container" */>
        {loading && message && (<div className="error">{message}</div>)}
            {/* <div className="register-header">
                <h5>Create Application Below</h5>
            </div> */}
            <div /* className="register-body" */>
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleRegisterApp} >
                          <div className="row rounded-3">
                          <div className="row">
                          <div className="col">
                          <h5 style={{ marginLeft: "10px", color: "#555" }}>Application</h5>
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={formData.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="version">Version*</label>
                            <input
                                type="text"
                                name="version"
                                className="form-control"
                                value={formData.version}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.version && <div className="form-error">{errors.version}</div>}
                        </div>
                        <div className="register-column-100">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={formData.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div> 
                        </div> 
                        </div>
                        <div className="row mt-3">
                        <div className="col">
                        <div /* className="col" */ style={{ display: "flex" }}>
                        {/* <div className="col-md-10"> */}
                        <h5 style={{ marginLeft: "10px", color: "#555" }}>Environments</h5>
                        </div>

                        {formDataArray.map((item, index) => (
        <div  key={index}>
                        <div className="register-column-40">
                            <label htmlFor="env">Env Name*</label>
                            <input
                                type="text"
                                name="env"
                                className="form-control"
                                value={item.env}
                                onChange={(e) => handleChangeArray(e, index)}
                                autoComplete="off"
                                required
                            />
                            {errors.env ? <div className="form-error">{errors.env}</div> : <div className="form-info">enter env name eg. production, demo, dev</div>}
                        </div>
                        <div className="register-column-40">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={item.endpoint}
                                onChange={(e) => handleChangeArray(e, index)}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint ? <div className="form-error">{errors.endpoint}</div> : <div className="form-info">enter the endpoint url</div>}
                        </div>

                        <div className="register-column-20">
          <label htmlFor="actions">Actions</label> <br />
          {formDataArray.length > 1 && (
            <button className="btn btn-secondary btn-sm" onClick={() => handleDeleteInput(index)}>Delete</button>
          )}
          {index === formDataArray.length - 1 && (
            <button className="btn btn-primary btn-sm" onClick={() => handleAddInput()}>Add</button>
          )}
          </div>

                        </div>
                        ))}

                        {/* <div className="register-column-100" style={{ marginTop: "20px", marginBottom: "20px" }}> {JSON.stringify(formData)} </div>
                        <div className="register-column-100" style={{ marginTop: "20px", marginBottom: "20px" }}> {JSON.stringify(formDataArray)} </div> */}

                       {/*  {formDataArray.map((item, index) => (
        <div  key={index}>
          <div className="register-column-40">
          <label htmlFor="endpoint">Env Name*</label>
          <input
            name="env"
            type="text"
            className="form-control"
            value={item.env}
            onChange={(e) => handleChangeArray(e, index)}
            autoComplete="off"
            required
          />
          {errors.env ? <div className="form-error">{errors.env}</div> : <div className="form-info">enter env name eg. production, demo, dev</div>}
          </div>
          <div className="register-column-40">
          <label htmlFor="endpoint">Endpoint*</label>
          <input
            name="endpoint"
            type="text"
            className="form-control"
            value={item.endpoint}
            onChange={(e) => handleChangeArray(e, index)}
            autoComplete="off"
            required
          />
          {errors.endpoint ? <div className="form-error">{errors.endpoint}</div> : <div className="form-info">enter the endpoint url</div>}
          </div>
          <div className="register-column-20">
          <label htmlFor="actions">Actions</label> <br />
          {formDataArray.length > 1 && (
            <button className="btn btn-secondary btn-sm" onClick={() => handleDeleteInput(index)}>Delete</button>
          )}
          {index === formDataArray.length - 1 && (
            <button className="btn btn-primary btn-sm" onClick={() => handleAddInput()}>Add</button>
          )}
          </div>
        </div>
      ))} 

      <div className="register-column-100" style={{ marginTop: "20px" }}> {JSON.stringify(formDataArray)} </div> */}

                        </div>
                        </div>
                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Create App
                            </button>
                        </div>
                        </div>
                    </form>
                )}
                </div>
            </div>

        </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </div>
</div>

</div>
</section>
</main>
</section>
</section>

<div>
      {isLoadApplication &&
          <ViewApplication list={activeApplication} />
        }
        </div>
</div>
)}


  {currentUser && !(currentUser.role === "Admin" || currentUser.role === "Super Admin") &&
  <div className="page-container-static">
  <div className="login-prompt-container">
  <div className="error">
      {message} <br /> <br /> 
      Redirecting to Homepage....
  </div>
  </div>
  </div>}


</div>
    );
}

export default Register;