import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApplicationService from "../services/application-service/application-data-service";
import Modal  from "react-bootstrap/Modal";
import '../App.css'

const statusEnum = [
    { value: "good", label: "good" },
    { value: "warning", label: "warning" },
    { value: "bad", label: "bad" }
]


const UpdateApp = (loggedInUser) => {

    const params = useParams();
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState();
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const isApplication = useState({
      appName: "", version: "", endpoints: [{ env: "", endpoint: "" }], description: ""
    });

    const [activeApplication, setActiveApplication] = useState();
    const [formDataArray, setFormDataArray] = useState([{
      env: "", endpoint: "",
    }]);
    const [showModal, setShowModal] = useState(true);
    const handleCloseModal = () => { setShowModal(false); navigate("/home"); };
    const handleShowModal = () => setShowModal(true);
    
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleAddInput = () => {
      setFormDataArray([...activeApplication.endpoints, { env: "", endpoint: "" }]);
      //setActiveApplication([...activeApplication.endpoints, { env: "", endpoint: "" }]);
    };

    const handleChangeArray = (e, index) => {
      let { name, value } = e.target;
      let onChangeValue = [...formDataArray];
      onChangeValue[index][name] = value;
      //console.log(formData)
      //let appendedForm = formData.endpoints;
      //appendedForm.push(formDataArray);
      setFormDataArray(onChangeValue);
      //return appendedForm;
    };
  
    const handleDeleteInput = (index) => {
      const newArray = [...formDataArray];
      //const newArray = [...activeApplication.endpoints];
      newArray.splice(index, 1);
      setFormDataArray(newArray);
    };

    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
  
      //let onChangeValue = [...activeApplication.endpoints];
      //onChangeValue[index][name] = value;
      //console.log("changeValue: ", onChangeValue);
      //console.log("full: ", activeApplication);
  
      setActiveApplication((item) => ({
          ...item,
          [name]: value
      }));
      };
    
        
        const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!activeApplication.appName) {
            newErrors.appName = "App Name is required";
            isValid = false;
        }

        if (!activeApplication.application.version) {
            newErrors.version = "App Version is Required";
            isValid = false;
        }

        if (!activeApplication.description) {
          newErrors.description = "Description is required";
          isValid = false;
        }

        /* if (!activeApplication.endpoints[0].env) {
          newErrors.env = "Env is required";
          isValid = false;
        }

        if (!activeApplication.endpoints[0].endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        } */

        /* if (!formDataArray[0].env) {
          newErrors.env = "Env is required";
          isValid = false;
        }

        if (!formDataArray[0].endpoint) {
          newErrors.endpoint = "Endpoint is required";
          isValid = false;
        } */ 
    
        setErrors(newErrors);
        return isValid;
      };  

      

      

    useEffect(() => {
        window.scrollTo(0, 0);

        const user = loggedInUser.loggedInUser
        if (user) {
            setCurrentUser(user);
            if (!(user.role === "Admin" || user.role === "Super Admin")) {
                setMessage("Unauthorized to Update Applications!");
                setTimeout(() => {
                    setMessage("");
                    navigate("/home");
                    window.location.reload();}, 3300)
                    return;
            }; 
        } else {
          return null;
        };

        ApplicationService.viewApplicationById(params.id).then(
            (response) => { 
              const data = response.data;
              const filteredData = (item) => {
                let result = {
                  appName: item.appName, version: item.application.version, description: item.description, endpoints: item.endpoints
                };
                console.log(result);
                return result;
              };
              setActiveApplication(data);
              filteredData(data);
              return response.data;
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
          
              setMessage(message)
            }
          ); 

        
    }, [params, setCurrentUser, setActiveApplication, navigate, loggedInUser]);


    

    const handleUpdateApp = async (e) => {
        e.preventDefault();

        activeApplication.endpoints = new Array();
        let appendedForm = activeApplication.endpoints;
        let length = formDataArray.length;
        let lastArray = formDataArray[length - 1];
        appendedForm.push( ...formDataArray );
        //appendedForm.shift();
        //console.log(activeApplication)
        //console.log(formDataArray)

        if (validateForm()) { 
            ApplicationService.updateApplicationById(activeApplication._id, activeApplication.appName, activeApplication.version, 
              activeApplication.description, activeApplication.endpoints).then(
                () => {
                    setSuccess("Application Updated Successfully!");
                    setLoading(true);
                    console.log("Active Application:", activeApplication);
                    setSubmitted(true); 
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess("");
                        handleCloseModal();
                        window.location.reload();}, 2300)
                },
                (error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setMessage(message);
                }
            );
        
          } else {
            // Form is not valid, display error messages
          } }

          

    return (
<div className="App">
    {currentUser && activeApplication && (currentUser.role === "Admin" || currentUser.role === "Super Admin")
     && (<div>

{/* <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {data && data.length >= 1 ? (
                      data.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        <i className="bi bi-circle"></i><span>{item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>
          </section>
          </section> */}

     <div className="page-container-static" id="page_container_toggle">
      <div className="config-list-group-table">
      <Modal
        show={showModal}
        onHide={() => { handleCloseModal() }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          
          <div /* className="modal-header-sign-up" style={{ textAlign: "center" }} */>
            <h5 style={{ marginLeft: "10px", textAlign: "center", color: "#555" }}>Update Application Below - {activeApplication.appName} </h5>
            <p style={{ marginLeft: "10px", textAlign: "center", color: "#555" }}>Fill in the details below</p>
            {/* {message && (<div className="error">{message}</div>)} */}
          </div>

        </Modal.Header>
        

        <Modal.Body>
        <div /* className="register-container" */>
        {loading && message && (<div className="error">{message}</div>)}
            {/* <div className="register-header">
                <p>Update Application Below - {activeApplication.appName}</p>
            </div> */}
            <div /* className="register-body" */>
                <div className="col-md-12">
        {loading && submitted ? (<div className="info">{success}</div> 
        ) : (
                    <form className="insertForm"
                        onSubmit={handleUpdateApp} >
                        <div className="row rounded-3">
                        <div className="row">
                        <div className="col">
                        <h5 style={{ marginLeft: "10px", color: "#555" }}>Application</h5>
                        <div className="register-column">
                            <label htmlFor="appName">App Name*</label>
                            <input
                                type="text"
                                name="appName"
                                className="form-control"
                                value={activeApplication.appName}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.appName && <div className="form-error">{errors.appName}</div>}
                        </div>
                        <div className="register-column">
                            <label htmlFor="version">App Version*</label>
                            <input
                                type="text"
                                name="version"
                                className="form-control"
                                value={activeApplication.application.version}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.version && <div className="form-error">{errors.version}</div>}
                        </div>
                        <div className="register-column-100">
                            <label htmlFor="description">Description*</label>
                            <input
                                type="text"
                                name="description"
                                className="form-control"
                                value={activeApplication.description}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                            {errors.description && <div className="form-error">{errors.description}</div>}
                        </div>
                        
                        <div className="row mt-3">
                        <div className="col">
                        <div /* className="col" */ style={{ display: "flex" }}>
                        {/* <div className="col-md-10"> */}
                        <h5 style={{ marginLeft: "10px", marginTop: "10px", color: "#555" }}>Environments</h5>
                        </div>
                        </div> 
                        </div>

                        {/* activeApplication && activeApplication.endpoints.map((item, index) => (
        <div  key={index}>
                        <div className="register-column-40">
                            <label htmlFor="env">Env Name*</label>
                            <input
                                type="text"
                                name="env"
                                className="form-control"
                                value={item.env}
                                onChange={(e) => handleInputChange(e, index) /* (e) => handleChangeArray(e, index) /}
                                autoComplete="off"
                                required
                            />
                            {errors.env ? <div className="form-error">{errors.env}</div> : <div className="form-info">enter env name eg. production, demo, dev</div>}
                        </div>
                        <div className="register-column-40">
                            <label htmlFor="endpoint">Endpoint*</label>
                            <input
                                type="text"
                                name="endpoint"
                                className="form-control"
                                value={item.endpoint}
                                onChange={(e) => handleInputChange(e, index) /* (e) => handleChangeArray(e, index) /}
                                autoComplete="off"
                                required
                            />
                            {errors.endpoint ? <div className="form-error">{errors.endpoint}</div> : <div className="form-info">enter the endpoint url</div>}
                        </div>

                        <div className="register-column-20">
          <label htmlFor="actions">Actions</label> <br />
          {activeApplication.endpoints.length > 1 && (
            <button className="btn btn-secondary btn-sm" onClick={() => handleDeleteInput(index)}>Delete</button>
          )}
          {index === activeApplication.endpoints.length - 1 && (
            <button className="btn btn-primary btn-sm" onClick={() => handleAddInput()}>Add</button>
          )}
          </div> 

                        </div>
                        ))}

                        <div className="register-column-100" style={{ marginTop: "20px", marginBottom: "20px" }}> {JSON.stringify(activeApplication)} </div>
                        {/* <div className="register-column-100" style={{ marginTop: "20px", marginBottom: "20px" }}> {JSON.stringify(formDataArray)} </div> */} 

                       {formDataArray.map((item, index) => (
        <div  key={index}>
          <div className="register-column-40">
          <label htmlFor="endpoint">Env Name*</label>
          <input
            name="env"
            type="text"
            className="form-control"
            value={item.env}
            onChange={(e) => handleChangeArray(e, index)}
            autoComplete="off"
            /* required */
          />
          {errors.env ? <div className="form-error">{errors.env}</div> : <div className="form-info">enter env name eg. production, demo, dev</div>}
          </div>
          <div className="register-column-40">
          <label htmlFor="endpoint">Endpoint*</label>
          <input
            name="endpoint"
            type="text"
            className="form-control"
            value={item.endpoint}
            onChange={(e) => handleChangeArray(e, index)}
            autoComplete="off"
            /* required */
          />
          {errors.endpoint ? <div className="form-error">{errors.endpoint}</div> : <div className="form-info">enter the endpoint url</div>}
          </div>
          <div className="register-column-20">
          <label htmlFor="actions">Actions</label> <br />
          {formDataArray.length > 1 && (
            <button className="btn btn-secondary btn-sm" onClick={() => handleDeleteInput(index)}>Delete</button>
          )}
          {index === formDataArray.length - 1 && (
            <button className="btn btn-primary btn-sm" onClick={() => handleAddInput()}>Add</button>
          )}
          </div>
        </div>
      ))} 

      {/* <div className="register-column-100" style={{ marginTop: "20px" }}> {JSON.stringify(formDataArray)} </div>  */}

                        </div>
                        </div>

                        <div className="register-button-area">
                            <button className="register-button" type="submit" /* disabled={!isFormValid} */>
                                Update App
                            </button>
                        </div>
                        </div> 
                    </form>
                )}
                </div>
            </div>

        </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

    </div>
</div>
</div>
)}


  {currentUser && !(currentUser.role === "Admin" || currentUser.role === "Super Admin") &&
  <div className="page-container-static">
  <div className="login-prompt-container">
  <div className="error">
      {message} <br /> <br /> 
      Redirecting to Homepage....
  </div>
  </div>
  </div>}


</div>
    );
}

export default UpdateApp;