import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL; //process.env.REACT_APP_API_URL_DEV; //process.env.REACT_APP_API_URL;

const register = async (firstName, lastName, username, phoneNumber, email, password, role) => {
  return axios.post(API_URL + `user-register`, 
  {
    firstName, lastName, username, phoneNumber, email, password, role
  },
  { }
  );
};

const firstTimeLogin = (email, password, OTP_Code) => {
  return axios
    .post(API_URL + `users/account-verification/`, {
      email, password, OTP_Code
    })
    .then((response) => {
      try {
        
        if (response.data) {

        let user = response.data;
        let respToken = user.tokens[user.tokens.length - 1];
        let token = respToken.token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token));
        
        return user;

        };
      } catch (error) {
        console.error("Error setting user to local storage:", error);
        return null;
      };
      return response.data;
    });
};

const getOTP = async (email) => {
  return axios
    .post(API_URL + `users/forgot-password/`, {
      email
    })
    .then((response) => {
      /* if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      } */

      return response.data;
    });
};

const login = async (email, password) => {


  console.log(" login url ", API_URL + "user-login")
  return axios
    .post(API_URL + "user-login", {
      email,
      password,
    })
    .then((response) => {
      try {

        if (response.data) {

        let user = response.data;
        let respToken = user.tokens[user.tokens.length - 1];
        let token = respToken.token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token));
        
        return user;

        };
      } catch (error) {
        console.error("Error setting user to local storage:", error);
        return null;
      };
      return response.data;
    });
};

const recoverPasswordOTP = async (email, password, OTP_Code) => {
  return axios
    .post(API_URL + `users/recover-password/`, {
      email, password, OTP_Code
    })
    .then((response) => {
      try {
        if (response.data) {

        let user = response.data;
        let respToken = user.tokens[user.tokens.length - 1];
        let token = respToken.token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token));
        
        return user;
        };
      } catch (error) {
        console.error("Error setting user to local storage:", error);
        return null;
      };
      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}; 

/* const getCurrentUser = () => {

    const user = localStorage.getItem("user");} */
    /* if (user === undefined) {
      console.log(user)
      window.location.replace("/login");
      return;
    }  */
    /* if (user != undefined) {
    const userId = JSON.parse(localStorage.getItem("user"));
    const token = userId.tokens[userId.tokens.length - 1];
    const accessToken = token.token;

    if (userId && accessToken) {
      const decodedJwt = parseJwt(accessToken);


      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("user");
        window.location.replace("/login");
        return;
      }
    } 
    } };*/
  


const getCurrentUser = () => {
  let storedUser = JSON.parse(localStorage.getItem("user"));
  let storedToken = JSON.parse(localStorage.getItem("token"));

  try {
    if (storedToken && storedUser) {
    return storedUser ? storedUser : null;
    };
  } catch (error) {
    console.error("Error parsing user from sessionStorage:", error);
    return null;
  };
};  

const AuthService = {
  register,
  firstTimeLogin,
  getOTP,
  login,
  recoverPasswordOTP,
  logout,
  getCurrentUser,
};

export default AuthService;