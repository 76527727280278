import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

const Graph = () => {

    const [data, setData] = useState([35, 51, 49, 62, 69, 91]);
    const [memoryData, setMemoryData] = useState([35, 51, 49, 62, 69, 51, 23, 17, 29, 16, 30, 14]);
    const [storageData, setStorageData] = useState([25, 61, 29, 42, 59, 51, 53, 47, 39, 36, 53, 34]);
    const [timeData, setTimeData] = useState([25, 61, 29, 42, 59, 51, 53, 47, 39, 36, 53, 34]);
    const [message, setMessage] = useState();
    const [yaxisInterval, setYAxisInterval] = useState();

      const series = [
        {
          name: "Memory",
          data: memoryData,
        },
        {
          name: "Storage",
          data: storageData,
        }
      ];
    
      const options = {
        chart: {
          id: "realtime",
          height: 350,
          fontFamily: "Satoshi, sans-serif",
          type: "area",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 2000
            }
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          width: [2, 2],
          curve: "smooth"
        },
        title: {
          text: "Dynamic Updating Chart",
          align: "center",
          style: {
            fontSize: "17px",
            color: "#555",
            fontWeight: "700"
          },
        },
        markers: {
          size: 4,
          colors: "#fff",
          strokeColors: ["#3056D3", "#80CAEE"],
          strokeWidth: 3,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          hover: {
            size: undefined,
            sizeOffset: 5,
          },
        },
        /* yaxis: {
          max: yaxisInterval
        }, */
        xaxis: {
          type: 'category',
          categories: timeData, 
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          title: {
            text: "Timeline",
            style: {
              fontSize: "15px",
              color: "#555",
              fontWeight: "600"
            },
          },
        },
        yaxis: {
          title: {
            text: "(GB)",
            style: {
              fontSize: "15px",
              color: "#555",
              fontWeight: "600"
            },
          },
        },
        legend: {
          show: true
        }
      };
    

    useEffect(() => {

          const interval = setInterval(() => {
            /* const val = Math.floor(Math.random() * (100 - 30 + 1)) + 30;
            let array = [...data, val];
            array.shift();
            setData(array); */

            axios({
                method: 'get',
                url: "https://dalashabima.com:1027/api/utility/health" //endPoints[2].env[0].link,
              })
                .then((response) => {
                  const result = response.data.data;
                  let usedMemoryData = Math.round(result.health.memory.used / 1000000);
                  let usedStorageData = Math.round(result.health.storage.used / 1000000000);
                  let newTime = new Date().toLocaleTimeString();
                  let memoryArray = [...memoryData, usedMemoryData];
                  let storageArray = [...storageData, usedStorageData];
                  let timeArray = [...timeData, newTime];
                  memoryArray.shift();
                  storageArray.shift();
                  timeArray.shift();
                  setYAxisInterval(usedMemoryData + 20);
                  setMemoryData(memoryArray);
                  setStorageData(storageArray);
                  setTimeData(timeArray);
                  return;
              })
              .catch((error) => {
                const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              
                  setMessage(message);
              });

          }, 2000);
          return () => {
            window.clearInterval(interval); // clear the interval in the cleanup function
          };

    }, [data, memoryData, storageData, timeData]);



    return (
        <div className="App">

             <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>
      
      
      <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span> Test 1</span>
                      </li>
                      <li className="nav-item">
                        <span> Test 2</span>
                      </li>
                      <li className="nav-item">
                        <span> Test 3</span>
                      </li>
                      <li className="nav-item">
                        <span> Test 4</span>
                      </li>
                    </ul>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>
        
          <main className="dashboard col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="pagetitle">
          <h1> Graph</h1>
          <nav className="breadcrumbs">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/home">Home</a></li>
            <li className="breadcrumb-item"><a className="breadcrumb-menu-a" href="/applications">Applications</a></li>
            <li className="breadcrumb-item"><span style={{ backgroundColor: "#555", color: "white", borderRadius: "5px", padding: "5px" }}>Graph</span></li>
          </ol>
          </nav>
        </div>
        <section className="section">
          
        </section>

        <section className="section dashboard">
          <div className="row">

            <div className="col-lg-12">
            <div className="col-xxl-4 col-xl-12">
                <div className="row">
                <div className="col-xxl-4 col-md-12">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <div className="row align-items-start">


        <div id="chartOne" className="ml-5">
          <Chart options={options} series={series} type="area" width={"100%"} height={350} />
        </div>
        </div>
        </div>

        </div>
                  </div>
                  </div>

                </div>
              </div>
              </div>
          </section>

  



        </main>
        
        </section> 
      </section> 

            </div>
    );
};

export default Graph;
