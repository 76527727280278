/* import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; */


export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.tokens[user.tokens.length - 1];
  const accessToken = token.token; 

  if (user && accessToken) {
    return { Authorization: 'Bearer ' + accessToken };
  } else {
    return {};
  } 
    /* if (user && user.accessToken) {
      return { Authorization: 'Bearer ' + user.accessToken };
    } else {
      return {};
    } */
}

/* export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const AuthVerify = (props) => {
  let location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.tokens[user.tokens.length - 1];
    const accessToken = token.token;

    if (user && accessToken) {
      const decodedJwt = parseJwt(accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  }, [location, props]);

  return ;
}; */