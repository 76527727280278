import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
import AuthService from "../services/user-service/auth-service";
import '../App.css'

const Login = (loggedOutUser) => {

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [logOutMessage, setLogOutMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  if (loggedOutUser && loggedOutUser.loggedOutUser) {
    return setLogOutMessage(loggedOutUser.loggedOutUser);
  };
  
  }, [loggedOutUser])
  //console.log(logOutMessage);

  // Logging in

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLogOutMessage("")

      AuthService.login(email, password).then(
        () => {
          setSuccess("Login Successfull!");
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            setSuccess("");
            navigate("/home");
            window.location.reload()}, 2300)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
  };

  return (
    <div /* className="App" */>
      
{/* <div className="page-container-static-auth">
  <div className="config-list-group-auth"> */}

		<link rel="stylesheet" href={require("../../src/assets/css/bootstrap.min.css")}></link>

  <section className="vh-100" style={{ backgroundColor: "#EFEFf0" }}>
	  <div className="container py-5 h-100">
		<div className="row d-flex justify-content-center align-items-center h-100">
		  <div className="col col-xl-10">
			<div className="card" style={{ borderRadius: "1rem" }}>
			  <div className="row g-0">
				<div className="col-md-6 col-lg-5 d-none d-md-block">
				  <img src={require("../../src/assets/img/img1.webp")}
					alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
				</div>
				<div className="col-md-6 col-lg-7 d-flex align-items-center">
				  <div className="card-body p-4 p-lg-5 text-black">

					<form onSubmit={handleLogin}>

					  <div className="d-flex align-items-center mb-3 pb-1">
						<i className="fas fa-cubes fa-2x me-3" style={{ color: "#ff6219" }}></i>
						<span className="h1 fw-bold mb-0">Nanatec</span>
					  </div>

					  <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>Sign into your account</h5>
            {logOutMessage && (<div className="warning">{logOutMessage}</div>)}
      {loading && success && <div className="info">{success}</div>}
      {message && (<div className="error">{message}</div>)}
					  <div data-mdb-input-init className="form-outline mb-4">
						<input type="email" id="form2Example17" className="form-control form-control-lg" value={email}
                      onChange={(e)=>setEmail(e.target.value)}/>
						<label className="form-label" htmlFor="form2Example17">Email address</label>
					  </div>

					  <div data-mdb-input-init className="form-outline mb-4">
						<input type="password" id="form2Example27" className="form-control form-control-lg" value={password}
                      onChange={(e)=>setPassword(e.target.value)} />
						<label className="form-label" htmlFor="form2Example27">Password</label>
					  </div>

					  <div className="pt-1 mb-4">
						<button data-mdb-button-init data-mdb-ripple-init className="btn btn-dark btn-lg btn-block" type="submit" /* type="button" */>Login</button>
					  </div>

					  <a href="#!" className="small text-muted">Terms of use.</a>
					  <a href="#!" className="small text-muted">Privacy policy</a>
					</form>

				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
    </section>
    {/* <div className="login-container">
      {logOutMessage && (<div className="warning">{logOutMessage}</div>)}
      {loading && success && <div className="info">{success}</div>}
      {message && (<div className="error">{message}</div>)}
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p>Enter your email and password below</p>
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={handleLogin}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="pass-wrapper">
                    <label htmlFor="password">Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                      placeholder="Enter Password"
                      autoComplete="off"
                      required
                    />
                    <i className="pass-icon" 
                        onClick={togglePasswordVisiblity}
                    >
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiFillEye />}
                    </i>
                  </div>
                  <div className="forgot-password" style={{ textAlign: "right" }}>
                     <a href={"/forgot-password"}> forgot password?</a>
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        Login
                    </button>
                  </div>
              </form>
          </div>
        </div>

    </div> */}
  {/* </div>
</div> */}
</div>
  );
}

export default Login;