import React, { useEffect, useState } from "react";
import UserService from "../services/user-service/user-data-service";
import ViewUser from "./View-User";
import '../App.css';
import { Link } from "react-router-dom";
import { BsPlus, BsArrowUpRightSquare, BsGripVertical, BsGripHorizontal, BsPencil, BsTrash, BsEye } from "react-icons/bs";
import { FaClock, FaUsers } from "react-icons/fa";
import Avatar from "react-avatar";
import { MdDeveloperMode, MdManageAccounts } from "react-icons/md";
import ViewApplication from "./View-Application";
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import Login from "./Login";

const rolesEnum = [
  { value: "", label: "", name: "" },
  { value: "Developer", label: "Developer", name: "Developer" },
  { value: "Admin", label: "Admin", name: "Admin" },
]

const rolesEnumSU = [
  { value: "", label: "", name: "" },
  { value: "Developer", label: "Developer", name: "Developer" },
  { value: "Admin", label: "Admin", name: "Admin" },
]

const statusEnum = [
  { value: "", label: "", name: "" },
  { value: "Active", label: "Active", name: "Active" },
  { value: "Pending", label: "Pending", name: "Pending" }
]

const Users = (loggedInUser) => {

  const [currentUser, setCurrentUser] = useState();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(5);
  const [pageCount, setPageCount] = useState(0)
  const isUser = useState({ id: "", firstName: "", lastName: "", username: "",
                            phoneNumber: "", email: "", role: "", status: "" });
  const isApplication = useState({ id: "", appName: "", storageEndpoint: "", storageEndpointResponses: [],
        storageStatus: "", upTimeEndpoint: "", upTimeEndpointResponses: [], upTimeStatus: "", memoryEndpoint: "", 
        memoryEndpointResponses: [], memoryStatus: "", createdBy: "", notes: "" });
  const [data, setData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [activeUser, setActiveUser] = useState(isUser);
  const [isLoadUser, setLoadUser] = useState(false);
  const [userTotal, setUserTotal] = useState(0);
  const [userActiveTotal, setUserActiveTotal] = useState(0);
  const [userDeveloperTotal, setUserDeveloperTotal] = useState(0);
  const [userAdminTotal, setUserAdminTotal] = useState(0);
  const [activeApplication, setActiveApplication] = useState(isApplication);
  const [isLoadApplication, setLoadApplication] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  
  const [showModal, setShowModal] = useState(false);
  const [isCreateUser, setCreateUser] = useState(false);
  const [isEditUser, setEditUser] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [isLoadManageUsers, setLoadManageUsers] = useState(false);
  const toggleManageUsersVisiblity = () => {
    setLoadManageUsers(isLoadManageUsers ? false : true);
  };


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage * 5)
  };


  useEffect(() => {

    const userData = loggedInUser.loggedInUser;
    const appData = loggedInUser.data;

    setCurrentUser(userData);
    setAppData(appData);

    UserService.allUsers().then(
      (response) => {
        const data = response.data.reverse();
        const total = data.length;
        const active = data.filter(item => item.status === "Active").length;
        const developer = data.filter(item => item.role === "Developer").length;
        const admin = data.filter(item => (item.role === "Admin" || item.role === "Super Admin")).length;
        const slice = data.slice(offset, offset + perPage);
        const postData = slice.map(item => ({
          id: item._id, firstName: item.firstName, lastName: item.lastName, username: item.username,
          phoneNumber: item.phoneNumber, email: item.email, role: item.role, status: item.status
        }))
        setUserTotal(total);
        setUserActiveTotal(active);
        setUserDeveloperTotal(developer);
        setUserAdminTotal(admin);
        setData(postData);
        setPageCount(Math.ceil(data.length / perPage) * 2);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(message)
      }
    );

  

    /* ApplicationService.allApplications().then(
      (response) => {
        const data = response.data.reverse();
        const postData = data.map(item => ({
          id: item._id, appName: item.appName, storageEndpoint: item.storageEndpoint, storageEndpointResponses: item.storageEndpointResponses,
          storageStatus: item.storageStatus, upTimeEndpoint: item.upTimeEndpoint, upTimeEndpointResponses: item.upTimeEndpointResponses, 
          upTimeStatus: item.upTimeStatus, memoryEndpoint: item.memoryEndpoint, memoryEndpointResponses: item.memoryEndpointResponses, 
          memoryStatus: item.memoryStatus, createdBy: item.createdBy, notes: item.notes
        }))
        setAppData(postData);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(message)
      }
    ); */


    setActiveApplication(activeApplication);

    setActiveUser(activeUser);


  }, [offset, perPage, activeApplication, setCurrentUser, activeUser, loggedInUser, setAppData]);


  const createUser = async (e) => {
    e.preventDefault();

    UserService.createUser(firstName, lastName, username, phoneNumber, email, password, role).then(
      () => {
        setLoading(true);
        setSuccessMessage("User Created Successfully!");
        setTimeout(() => {
        setActiveUser([]);
        UserService.allUsers();
        window.location.reload();}, 2300);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          setLoading(true);
          setMessage(message);
          setTimeout(() => {
          setLoading(false);}, 4300);
      }
    );
  };


  const setViewActiveApplication = (application) => {
    setActiveApplication(application);
    setLoadApplication(true);
  };

  const setViewActiveUser = (user) => {
    setActiveUser(user);
    setLoadUser(true);
  };

  const setActiveUserById = (user) => {
    setActiveUser(user);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setActiveUser((activeUser) => ({
        ...activeUser, 
        [name]: value }));
  };


  const editUser = async (e) => {
    e.preventDefault();
    UserService.updateUserById(activeUser.id, activeUser.firstName, activeUser.lastName, 
      activeUser.username, activeUser.phoneNumber, activeUser.role, activeUser.status).then(
      () => {
        setLoading(true);
        setSuccessMessage("User Updated Successfully!");
        setTimeout(() => {
        setActiveUser([]);
        UserService.allUsers();
        window.location.reload();}, 2300);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(true);
        setMessage(message);
        setTimeout(() => {
        setLoading(false);}, 4300);
      }
    );
  };

  const deleteConfirm = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteUser(id);
    }
  };
  const deleteUser = async (id) => {
    try {
      UserService.deleteUserById(id)
        .then(() => {
          setActiveUser([]);
          UserService.allUsers();
          window.location.reload();
          return;
        })
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      setMessage(message);
    }
  }



  var textFile = () => {
    const element = document.createElement("a");
    const textFile = new Blob([[JSON.stringify(data)], { type: 'text/plain' }]); //pass data from localStorage API to blob
    element.href = URL.createObjectURL(textFile);
    element.download = "users.txt";
    document.body.appendChild(element);
    element.click();
  }



  let counter = 0; counter++;

  return (
  <div className="App">
    {currentUser && <div>

      <link rel="stylesheet" href={require("../assets/css/style.css")}></link>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css"></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap.min.css")}></link>
      <link rel="stylesheet" href={require("../assets/css/bootstrap-reboot.min.css")}></link>

      <section className="container-fluid">
        <section className="row">
          <aside className="sidebar sidebar-nav border border-right col-md-3 col-lg-2">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/home">
                    <i className="bi-house-fill"></i>
                    Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="/applications">
                      <i className="bi bi-grid"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    {appData && appData.length >= 1 ? (
                      appData.map((item, key) =>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <Link to={`../view-application/${item.id}`}>
                      <li className="nav-item" key={key} onClick={ () => setViewActiveApplication(item, item.id) }>
                        {/* <i className="bi bi-circle"></i> */}
                        {(item.appName === "Ibima" || item.appName === "Bima" || item.appName === "Nanatec" || 
                            item.appName === "Nexus" || item.appName === "Unitrust") ? 
                            
                          <img src={require(`../images/${item.appName}-logo.png`)} 
                          alt="" style={{ width: "20px", height: "20px", borderRadius: "50%" }}/>
                          
                          : 
                          <Avatar name={item.appName + " " + item.appName} size="20" round={true} />
                        }
                        <span> {item.appName}</span>
                      </li>
                      </Link>
                    </ul>)
                  ) : (
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li className="nav-item">
                        <span>No Apps Found!</span>
                      </li>
                    </ul>
                  )}
                    </li>
                    <li className="nav-item">
                    <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/users">
                    <i className="bi-people"></i>
                    Users
                    </a>
                  </li>
                  </ul>
              </div>
            </div>
          </aside>

        </section>
            
          </section>
          
      
      <div className='page-container' id="page_container_toggle">
      <div className="config-list-group-table">
      <div className="header-stats">
        <div className="first-stats" align="center">
          <div style={{float: "left", marginLeft: "20px"}}>
            <FaUsers style={{fontSize: "65px", verticalAlign: "middle"}}/>
          </div>
          <div style={{float: "right", marginRight: "20px", verticalAlign: "middle"}}>
            <h6 className="stats-font">Total</h6>
            <h4>{userTotal}</h4>
          </div>
        </div>
        <div className="second-stats" align="center">
          <div style={{float: "left", marginLeft: "20px"}}>
            <FaClock style={{fontSize: "60px", verticalAlign: "middle"}}/>
          </div>
          <div style={{float: "right", marginRight: "20px", verticalAlign: "middle"}}>
            <h6 className="stats-font">Active</h6>
            <h4>{userActiveTotal}</h4>
          </div>
        </div>
        <div className="third-stats" align="center">
          <div style={{float: "left", marginLeft: "20px"}}>
            <MdDeveloperMode style={{fontSize: "60px", verticalAlign: "middle"}}/>
          </div>
          <div style={{float: "right", marginRight: "20px", verticalAlign: "middle"}}>
            <h6 className="stats-font">Developers</h6>
            <h4>{userDeveloperTotal}</h4>
          </div>
        </div>
        <div className="fourth-stats" align="center">
          <div style={{float: "left", marginLeft: "20px"}}>
            <MdManageAccounts style={{fontSize: "60px", verticalAlign: "middle"}}/>
          </div>
          <div style={{float: "right", marginRight: "20px", verticalAlign: "middle"}}>
            <h6 className="stats-font">Admins</h6>
            <h4>{userAdminTotal}</h4>
          </div>
        </div>
      </div>
      <div className="table-header">
        <div className="table-header-title">
          <h5 style={{color: "#555"}}>Users</h5>
        </div>
        <div className="table-header-create">
          {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && 
          <button className="btn btn-primary" onClick={() => { setCreateUser(true); handleShowModal() }}>
            <BsPlus></BsPlus> Create New User
          </button>}
        </div>
        {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && 
        <div className="table-header-export" onClick={() => textFile()}>
          <BsArrowUpRightSquare></BsArrowUpRightSquare> Export to JSON
        </div>}
      </div>
      <div id="nav" className="list-group">
        <table className="config-list-table">
          <thead>
            <tr className="list-table-row" style={{color: "#555"}}>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>#</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>Name</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>Status</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>Phone Number</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>Email</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>Role</th>
              <th style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>
              {isLoadManageUsers ? <BsGripHorizontal /> : <BsGripVertical /> } Manage Users
              </th>
            </tr>
          </thead>
          <tbody>
            {data && data.length >= 1 ?(
              data.map((item, key) =>
              <tr key={key} className="list-table-row">
                <td style={{paddingLeft: "10px"}}>{counter++}.</td>
                <td style={{paddingLeft: "10px"}}>{item.firstName + " " + item.lastName}</td>
                {item.status === "Active" && <td style={{paddingLeft: "10px"}}>
                  <span className="badge rounded-pill bg-info">{item.status}</span>
                </td>}
                {item.status === "Inactive" && <td style={{paddingLeft: "10px"}}>
                  <span className="badge rounded-pill bg-warning">{item.status}</span>
                </td>}
                <td style={{paddingLeft: "10px"}}>+(254) {item.phoneNumber}</td>
                <td style={{paddingLeft: "10px"}}>{item.email}</td>
                {item.role === "Super Admin" && <td style={{paddingLeft: "10px"}}>
                  <span className="badge rounded-pill bg-success">{item.role}</span>
                </td>}
                {item.role === "Admin" && <td style={{paddingLeft: "10px"}}>
                  <span className="badge rounded-pill bg-primary">{item.role}</span>
                </td>}
                {item.role === "Developer" && <td style={{paddingLeft: "10px"}}>
                  <span className="badge rounded-pill bg-secondary">{item.role}</span>
                </td>}
                <td style={{paddingLeft: "10px", position: "relative", display: "block"}}>
                  <div className="table-manage-icon" onClick={toggleManageUsersVisiblity}>
                    {isLoadManageUsers ? <BsGripHorizontal /> : <BsGripVertical /> }
                  </div>
                  {isLoadManageUsers ? <div className="table-manage-buttons">
                  <Link to={`../view-user/${item.id}`}><div className="config-list-button-view-container"> View <br />
                    <button className="config-list-button-view"
                      onClick={() => setViewActiveUser(item, item.id)}
                    ><BsEye style={{ fontSize: "18px" }} /> </button>
                    </div>
                  </Link>
                  {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && <div className="config-list-button-edit-container"> Edit <br />
                  <button className="config-list-button-edit"
                    onClick={() => { setActiveUserById(item, item.id); setEditUser(true); handleShowModal() }}
                  ><BsPencil style={{ fontSize: "18px" }} /> </button>
                  </div>}
                  {(currentUser.role === "Admin" || currentUser.role === "Super Admin") && <div className="config-list-button-delete-container"> Delete <br />
                  <button className="config-list-button-delete"
                    onClick={() => { setActiveUserById(item.id); deleteConfirm(item.id) }}
                  ><BsTrash style={{ fontSize: "18px" }}/> </button>
                  </div>}
                  </div> : null }
                </td>
              </tr>)
              ) : (
                <tr className="list-table-row" style={{color: "#555"}}>
                  <td style={{padding: "10px", textAlign: "center"}} colSpan={6}>No Users Found!</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="table-page">
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />
      </div>
      

{isCreateUser && <div>
<Modal
        show={showModal}
        onHide={() => { setCreateUser(false); handleCloseModal() }}
        backdrop="static"
        keyboard={false}
        /* size="md" */
      >
        <Modal.Header closeButton>
          
          <div className="modal-header-sign-up" style={{ textAlign: "center" }}>
            <h5>Create New User</h5>
            <p>Fill in the details below</p>
          </div>

        </Modal.Header>
        <Modal.Body>

        <div className="modal-body-sign-up">
            <div className="col-md-12">
              <form className="insertForm"
                onSubmit={createUser} >
                <div className="register-column">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="register-column">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="register-column">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="register-column">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="register-column">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="register-column">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                {currentUser.role === "Super Admin" && <div className="register-column">
                  <label htmlFor="role">Role</label>
                  <select
                    name="role"
                    className="form-control"
                    value={role} 
                    onChange={(e) => setRole(e.target.value)}
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    {rolesEnumSU.map((role) => 
                    <option key={role.value} value={role.value}>{role.label}</option>)}
                  </select>
                </div>}
                {currentUser.role === "Admin" && <div className="register-column">
                  <label htmlFor="role">Role</label>
                  <select
                    name="role"
                    className="form-control"
                    value={role} 
                    onChange={(e) => setRole(e.target.value)}
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    {rolesEnum.map((role) => 
                    <option key={role.value} value={role.value}>{role.label}</option>)}
                  </select>
                </div>}
                <div className="register-button-area">
                  <button className="register-button" type="submit">
                    Create New User
                  </button>
                </div>
                {isLoading && message && (<div className="error"> {message} </div>)}
                {isLoading && successMessage && (<div className="success"> {successMessage} </div>)}
              </form>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
</div>}

{isEditUser && <div>
<Modal
        show={showModal}
        onHide={() => { setEditUser(false); handleCloseModal() }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>

          <div className="modal-header-sign-up" align="center">
            <h5>Edit User</h5>
            <p>Fill in the details below</p>
          </div>

        </Modal.Header>
        <Modal.Body>

        <div className="modal-body-sign-up">
            <div className="col-md">
              <form className="insertForm"
                onSubmit={editUser} >
                <div className="modal-sign-up-column">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={activeUser.firstName}
                    onChange={handleChange}
                    placeholder="Enter First Name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={activeUser.lastName}
                    onChange={handleChange}
                    placeholder="Enter Last Name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={activeUser.username}
                    onChange={handleChange}
                    placeholder="Enter Username"
                    autoComplete="off"
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    value={activeUser.phoneNumber}
                    onChange={handleChange}
                    placeholder="Enter Phonenumber"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="role">Role</label>
                  <select
                    name="role"
                    className="form-control"
                    value={activeUser.role} 
                    onChange={handleChange}
                    placeholder="Select A Role"
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    {rolesEnum.map((role) => 
                    <option key={role.value} value={role.value}>{role.label}</option>)}
                  </select>
                </div>
                <div className="modal-sign-up-column">
                  <label htmlFor="status">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={activeUser.status} 
                    onChange={handleChange}
                    placeholder="Select A Status"
                    autoComplete="off"
                    issearchable="true"
                    required
                  >
                    {statusEnum.map((status) => 
                    <option key={status.value} value={status.value}>{status.label}</option>)}
                  </select>
                </div>
                <div className="register-button-area">
                  <button className="register-button" type="submit">
                    Update User Info
                  </button>
                </div>
                {isLoading && message && (<div className="error"> {message} </div>)}
                {isLoading && successMessage && (<div className="success"> {successMessage} </div>)}
              </form>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
</div>}

      <div>
        {isLoadUser &&
          <ViewUser list={activeUser} />
        }
      </div>
      <div>
      {isLoadApplication &&
          <ViewApplication list={activeApplication} />
        }
        </div>
      </div>
    </div>
    </div>
    /* ) : (
      <div>
      {!currentUser && <Login />}
      </div>
    ) */}
  </div>
  );
}

export default Users;