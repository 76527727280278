import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
import AuthService from "../services/user-service/auth-service";
import '../App.css'

const FirstTimeLogin = () => {

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const isUser = useState({ _id: "", firstName: "", lastName: "", username: "", phoneNumber: "",
  email: "", role: "", profilePicture: "", accessTokens: "" })
  const [currentUser, setCurrentUser] = useState(isUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [OTP_Code, setOTPCode] = useState("");
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoadFTL, setLoadFTL] = useState(true);
  const [isLoadEmailOTP, setLoadEmailOTP] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    
    const user = AuthService.getCurrentUser();
    setCurrentUser(user);

    
  }, [setCurrentUser, setLoading, setNotification, setSuccess]);



  // First Time Logging in

  const handleFirstTimeLogin = (e) => {
    e.preventDefault();

    setMessage("");
      AuthService.firstTimeLogin(email, password, OTP_Code).then(
        (response) => {
          setLoading(true);
          setSuccess(`First Time Login Successfull!`);
          setCurrentUser(response.data);
          setTimeout(() => {
            setLoading(false);
            setSuccess("");
            navigate("/home");
            window.location.reload();}, 2300);
            return;
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
  };


  const generateOTP_Code = () => {
    AuthService.getOTP(email).then(
      () => {  
        setLoading(true);
        setNotification("OTP successfully sent to your email for password change!");
        setTimeout(() => {
          setNotification("");
          setLoading(false);
          window.location.reload();}, 2300);
        return; 
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
    
        setMessage(message)
      }
    );
  }


  return (
    <div className="App">
    <div className="page-container-static-auth">
    <div className="config-list-group-auth">
    <div className="login-container">
    {loading && success && (<div className="info">
      {success} 
      </div>)}
    {loading && message && (<div className="error">
      {message} 
      </div>)}
      {isLoadFTL && <div>
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p style={{ fontSize: "13px" }}>Fill in OTP and Email to Login</p>
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={handleFirstTimeLogin}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="pass-wrapper">
                    <label htmlFor="password">Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Password"
                      autoComplete="off"
                      required
                    />
                    <i className="pass-icon" 
                        onClick={togglePasswordVisiblity}
                    >
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiFillEye />}
                    </i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="OTP_Code">OTP</label>
                    <input
                      type="text"
                      name="OTP_Code"
                      className="form-control"
                      value={OTP_Code}
                      onChange={(e) => setOTPCode(e.target.value)}
                      placeholder="Enter OTP"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="forgot-password" style={{ textAlign: "right" }}>
                      <a href="/forgot-password">forgot password?</a>
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        First Time Login
                    </button>
                  </div>
                  <div className="register-after-button-area" 
                    onClick={() => { setLoadEmailOTP(true); setLoadFTL(false); }}>
                  <label htmlFor="Register"></label>
                    Did not receive OTP?
                  </div>
              </form>
          </div>
        </div>
        </div>}
        {isLoadEmailOTP && <div>
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p style={{ fontSize: "13px" }}>Fill in Email to Receive OTP</p>
          {loading && notification && (<div className="info">{notification}</div>)}
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={generateOTP_Code}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="forgot-password" style={{ textAlign: "right" }}
                    onClick={() => { setLoadEmailOTP(true); setLoadFTL(false); }}>
                    Did not receive OTP?
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        Resend OTP
                    </button>
                  </div>
              </form>
          </div>
        </div>
        </div>}
        </div>
    </div>
    </div>
    </div>
  );
}

export default FirstTimeLogin;