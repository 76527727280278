import axios from "axios";
import authHeader from "../user-service/auth-header-service";

const API_URL = process.env.REACT_APP_API_URL; //process.env.REACT_APP_API_URL_DEV; //process.env.REACT_APP_API_URL;

const allApplications = async () => {
  return axios.get(API_URL + "api/all-apps", { headers: authHeader() });
};

const deleteApplicationById = async (id) => {
  return axios.delete(API_URL + `api/delete-app/${id}`, { headers: authHeader() });
};

const createApplication = async (appName, version, description, endpoints) => {
  return axios.post(API_URL + `api/register-new-app/`,
  { appName, version, description, endpoints },
  { headers: authHeader() });
};

const createTestApplication = async (appName, version, appEnv, runTime, livenessStatus, memoryTotal, memoryUsed, 
  storageTotal, storageUsed, serverType, serverOS, numberOfCalls, deploymentType, deploymentProvider, envName, 
  description, endpoint, notes) => {
  return axios.post(API_URL + `api/register-new-test-app/`,
  { appName, version, appEnv, runTime, livenessStatus, memoryTotal, memoryUsed, storageTotal, storageUsed, 
    serverType, serverOS, numberOfCalls, deploymentType, deploymentProvider, envName, description, endpoint, notes },
  { headers: authHeader() });
};

const viewApplicationById = async (id) => {
  return axios.get(API_URL + `api/view-app/${id}`, { headers: authHeader() });
};

const updateApplicationById = async (id, appName, version, description, endpoints) => {
  return axios.patch(API_URL + `api/update-app/${id}`,
  { appName, version, description, endpoints },
  { headers: authHeader() });
};

const updateTestApplicationById = async (id, appName, envName, description, endpoint, storageStatus, upTimeStatus, 
  memoryStatus, notes) => {
  return axios.patch(API_URL + `api/update-test-app/${id}`,
  { appName, envName, description, endpoint, storageStatus, upTimeStatus, memoryStatus, notes },
  { headers: authHeader() });
};


const ApplicationService = {
  allApplications,
  deleteApplicationById,
  createApplication,
  createTestApplication,
  viewApplicationById,
  updateApplicationById,
  updateTestApplicationById,
};

export default ApplicationService;