import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/user-service/auth-service";
import '../App.css'

const ForgotPassword = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);


  const handleForgotPassword = (e) => {
    e.preventDefault();

    setMessage("");

      AuthService.getOTP(email).then(
        () => {
          setSuccess("OTP Sent to Email for Password recovery!");
          setLoading(true);
          setTimeout(() => {
            setSuccess();
            setLoading(false);
            navigate("/recover-password");
            window.location.reload();}, 2300);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
  };

  return (
    <div className="App">
<div className="page-container-static-auth">
  <div className="config-list-group-auth">
    <div className="login-container">
      {loading && success && (<div className="info">
      {success} 
      </div>)}
    {loading && message && (<div className="error">
      {message} 
      </div>)}
        <div className="register-header">
          <h5>LETAPELEKA ADMIN PORTAL</h5>
          <p style={{ fontSize: "13px" }}>Fill in Email to Receive OTP for Password Recovery</p>
        </div>
        <div className="register-body">
          <div className="col-md-12">
              <form className="insertForm" onSubmit={handleForgotPassword}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="login-button-area">
                    <button className="register-button" type="submit" value="Create Account">
                        Send OTP
                    </button>
                  </div>
              </form>
          </div>
        </div>
        </div>
        </div>
        </div>
    </div>
  );
}

export default ForgotPassword;