import axios from "axios";
import authHeader from "./auth-header-service"

const API_URL = process.env.REACT_APP_API_URL; //process.env.REACT_APP_API_URL;  //process.env.REACT_APP_API_URL_DEV; //        

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const allUsers = async () => {
  return axios.get(API_URL + "all-users", { headers: authHeader() });
};

const deleteUserById = async (id) => {
  return axios.delete(API_URL + `delete-user/${id}`, { headers: authHeader() });
};

const createUser = async (firstName, lastName, username, phoneNumber, email, password, role) => {
  return axios.post(API_URL + `user-register`,
  { firstName, lastName, username, phoneNumber, email, password, role },
  { headers: authHeader() });
};

const viewUserById = async (id) => {
  return axios.get(API_URL + `view-user/${id}`, { headers: authHeader() });
};

const viewMyProfile = async () => {
  return axios.get(API_URL + `view-my-profile/`, { headers: authHeader() });
};

const updateUserById = async (id, firstName, lastName, username, phoneNumber, role, status) => {
  return axios.patch(API_URL + `update-user/${id}`,
  { firstName, lastName, username, phoneNumber, role, status },
  { headers: authHeader() });
};

const updateMyProfile = async (firstName, lastName, username, phoneNumber) => {
  return axios.patch(API_URL + `update-my-profile/`,
  { firstName, lastName, username, phoneNumber },
  { headers: authHeader() });
};

const updateMyPassword = async (OTP_Code, password) => {
  return axios.patch(API_URL + `update-my-password/`,
  { OTP_Code, password },
  { headers: authHeader() });
};

const updateUserPasswordById = async (id, OTP_Code, password) => {
  return axios.patch(API_URL + `user/${id}/set-new-password`,
  { OTP_Code, password },
  { headers: authHeader() });
};

const deleteMyProfile = async () => {
  return axios.delete(API_URL + `delete-my-profile`, { headers: authHeader() });
};

const uploadUserProfilePictureById = async (id, formData) => {
  return axios.patch(API_URL + `user/${id}/upload-profile-picture`,
  { formData },
  { headers: authHeader() });
};


const uploadUserProfilePicture = async (formData) => {
  return axios.patch(API_URL + `upload-my-profile-picture`,
  {formData},
  { headers: authHeader() },
);
};

const viewUserProfilePicture = async () => {
  return axios.get(API_URL + `view-my-profile-picture`,
  { headers: authHeader() });
};

const deleteUserProfilePictureById = async (id) => {
  return axios.delete(API_URL + `user/${id}/delete-profile-picture`, { headers: authHeader() });
};

const deleteMyProfilePicture = async () => {
  return axios.delete(API_URL + `delete-my-profile-picture`, { headers: authHeader() });
};

const logOut = async () => {
  return axios.post(API_URL + `user/logout`,
  {  },
  { headers: authHeader() });
};


const generatePasswordResetOTP_Code = async () => {
  return axios.post(API_URL + `profile/get-verification/`,
  {  },
  { headers: authHeader() });
};

const generatePasswordResetOTP_CodeById = async (id) => {
  return axios.post(API_URL + `user/${id}/get-password-otp/`,
  {  },
  { headers: authHeader() });
};


const UserService = {
  getPublicContent,
  //register,
  allUsers,
  deleteUserById,
  createUser,
  viewUserById,
  updateUserById,
  deleteMyProfile,
  uploadUserProfilePicture,
  uploadUserProfilePictureById,
  logOut,
  updateMyProfile,
  updateMyPassword,
  viewMyProfile,
  generatePasswordResetOTP_Code,
  viewUserProfilePicture,
  generatePasswordResetOTP_CodeById,
  updateUserPasswordById,
  deleteMyProfilePicture,
  deleteUserProfilePictureById,
};

export default UserService;